<script>
export default {
  name: 'ListSearch',
  props: {
    searching: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      phrase: '',
      collapseForm: Boolean(
        this.$route.query.combo || this.$route.query.advanced
      ),
    };
  },
  computed: {
    active() {
      return this.$store.state.isSearchActive;
    },
    advanced() {
      return this.$route.query.advanced;
    },
    desktop() {
      return this.$util.mq('desktop');
    },
    tablet() {
      return this.$util.mq('tablet');
    },
  },
  watch: {
    searching: function(v) {
      this.collapseForm = v;
    },
    collapseForm: function(v) {
      this.$emit('collapse', v);
    },
    active: function(v) {
      if (v && this.$route.query.combo) {
        this.phrase = this.$route.query.combo;
        this.$store.state.isSearchActive = true;
      } else {
        this.phrase = '';
      }
      setTimeout(() => {
        this.focusOnInput();
      }, 50);
    },
  },
  methods: {
    search: function({ advanced = false } = {}) {
      const newQuery = { ...this.$route.query };

      if (advanced) {
        newQuery.advanced = true;
      } else {
        delete newQuery.advanced;
      }

      if (this.phrase && this.phrase.length && !advanced) {
        newQuery.combo = this.phrase;
      } else {
        delete newQuery.combo;
        this.phrase = '';
      }

      this.$router.replace({ query: newQuery });
    },
    checkPhrase: function(e) {
      if (e.code === 'Enter') {
        this.search();
        return;
      }
      const isQueryEmpty = Object.keys(this.$route.query).length === 0;

      if (!this.phrase && !isQueryEmpty) {
        this.$router.replace({ query: {} });
      }
    },
    focusOnInput() {
      this.$refs.inputElement.focus();
    },
  },
};
</script>

<template>
  <div v-if="active && !advanced">
    <div id="list-search" v-if="desktop || (!desktop && !collapseForm)">
      <div id="list-search--input" @click="focusOnInput">
        <input
          v-model="phrase"
          ref="inputElement"
          name="search-phrase"
          placeholder="Wpisz szukaną frazę"
          type="search"
          @keyup="checkPhrase"
        />
        <base-icon-search v-if="desktop" />
      </div>
      <div id="list-search--search">
        <button v-if="!desktop" class="search-mobile" @click="search">
          <base-icon-search />
        </button>
        <base-button v-else full @click="search">
          Szukaj
        </base-button>
      </div>
      <div>
        <base-button plain full @click="search({ advanced: true })">
          Więcej kryteriów
        </base-button>
      </div>
    </div>
    <base-button v-else full @click="collapseForm = false">
      Zmień kryteria
    </base-button>
  </div>
  <base-button
    v-else-if="active && advanced && !desktop"
    full
    @click="
      collapseForm = false;
      $store.state.advancedSearchOpen = true;
    "
  >
    Zmień kryteria
  </base-button>
</template>

<style scoped lang="scss">
#list-search {
  &--search {
    button.search-mobile {
      width: 100%;
      padding: 0.5rem 1.5rem;
      border-radius: 0.4rem;
      background-color: $primary;
      color: white;
      margin-bottom: 1rem;
    }
  }
  @include mq('desktop') {
    padding-inline: 1rem;
    display: grid;
    grid-template-columns: 1fr 150px 200px;
    gap: 20px;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  &--input {
    margin-bottom: 1rem;
    @include mq('desktop') {
      margin-bottom: 0;
      position: relative;
      &:hover {
        > svg {
          color: $secondary;
        }
      }
      > svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: text;
        color: $primary;
        transition: all ease-in-out 100ms;
      }
    }
  }
  input {
    width: 100%;
    padding: 1rem 1.2rem;
    padding-right: 4rem;
    background: $gray-6;
    border: none;
    outline: none;
    border-radius: 0.4rem;
    color: $gray-2;

    @include mq('desktop') {
      width: 100%;
      padding: 1rem 1.2rem;
      padding-right: 4rem;
      background: $gray-6;
      border: 2px solid $gray-5;
      outline: none;
      border-radius: 0.4rem;
      color: $gray-2;
      padding: 1rem 3rem 1rem 1rem;
      background: white;
      border-radius: 0.6rem;
    }
  }
}
</style>
